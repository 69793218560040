import React, { useState } from "react";
import axios from "axios";

function EmailForm() {
  const [recipientEmail, setRecipientEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disableButton) {
      return;
    }

    setLoading(true);
    try {
        const response = await axios.post('https://kn5ku0a7b4.execute-api.eu-west-2.amazonaws.com/send-email', {
            recipientEmail: recipientEmail,
        }, {
        headers: {
            'Access-Control-Allow-Origin': '*'
          }
        });
        console.log(response.data);
        setMessage(response.data.message);

      // Disable the button for 30 seconds after successful submission
      setDisableButton(true);
      setTimeout(() => {
        setDisableButton(false);
      }, 30000); // 30 seconds
    } catch (error) {
      console.error("Error sending email:", error);
      setMessage("Error sending email. Please try again later.");
    }
    setLoading(false);
  };

  const buttonTooltip = disableButton
    ? "Please wait before sending another email"
    : "";

  return (
    <div>
      <div className="container">
        <img src="/biLogo.png" alt="BI Logo"></img>
        <form onSubmit={handleSubmit}>
          <h1>
            Beginnen Sie heute noch mit der Aktivierung der People Promise!
          </h1>
          <h2>
            Geben Sie unten Ihre E-Mail-Adresse ein, um unsere hilfreichen
            Ressourcen zu erhalten, die Ihnen den Einstieg erleichtern.
          </h2>
          <label>
            <input
              type="email"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              required
            />
          </label>
          <button
            type="submit"
            disabled={loading || disableButton}
            title={buttonTooltip}
          >
            {loading ? "Senden anfordern..." : "Zugriff anfordern"}
          </button>
          <div className="message">{message && <p>{message}</p>}</div>
          <img className="graphic" src="/footerImage.jpg" alt="BI Logo"></img>
        </form>
      </div>
    </div>
  );
}

export default EmailForm;
